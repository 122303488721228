<template>
  <span>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="secondary" v-bind="attrs" v-on="on" @click="edit">
          <v-icon>{{ icon.edit }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Update
          {{ checkin.type === 1 ? 'User Checkin' : 'User Measurements' }}
        </v-card-title>

        <v-card-text class="pt-2">
          <v-text-field
            v-model="form.weight"
            label="Weight / kg"
            :error-messages="form.$getError('weight')"
            :hide-details="!form.$getError('weight')"
            outlined
            required
            flat
            dense
          />
          <v-text-field
            class="mt-4"
            v-model="form.chest"
            label="Chest / cm"
            :error-messages="form.$getError('chest')"
            :hide-details="!form.$getError('chest')"
            outlined
            required
            flat
            dense
          />
          <v-text-field
            class="mt-4"
            v-model="form.waist"
            label="Waist / cm"
            :error-messages="form.$getError('waist')"
            :hide-details="!form.$getError('waist')"
            outlined
            required
            flat
            dense
          />
          <v-text-field
            class="mt-4"
            v-model="form.hip"
            label="Hips / cm"
            :error-messages="form.$getError('hip')"
            :hide-details="!form.$getError('hip')"
            outlined
            required
            flat
            dense
          />
          <v-text-field
            class="mt-4"
            v-model="form.arms"
            label="Arms / cm"
            :error-messages="form.$getError('arms')"
            :hide-details="!form.$getError('arms')"
            outlined
            required
            flat
            dense
          />
          <v-text-field
            class="mt-4"
            v-model="form.legs"
            label="Legs / cm"
            :error-messages="form.$getError('legs')"
            :hide-details="!form.$getError('legs')"
            outlined
            required
            flat
            dense
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="secondary" text @click="dialog = false"> Close </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :loading="form.$busy" @click="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-toast ref="toast" />
  </span>
</template>

<script>
import VToast from '@/components/elements/Toast'
import { mapActions, mapState } from 'vuex'
import { mdiTableEdit } from '@mdi/js'
import Form from '@/utils/form'
import _ from 'lodash'
import Event from '@/services/eventBus'

export default {
  name: 'EditCheckinDialog',

  props: {},

  components: {
    VToast,
  },

  data() {
    return {
      icon: {
        edit: mdiTableEdit,
      },
      dialog: false,

      form: new Form({
        id: null,
        weight: null,
        chest: null,
        waist: null,
        hip: null,
        arms: null,
        legs: null,
      }),
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      checkin: (state) => state.users.selectedUserCheckin,
      selectedProgram: (state) => state.users.selectedUserProgram,
    }),
  },

  methods: {
    ...mapActions({
      updateUserCheckin: 'users/updateUserCheckin',
    }),

    edit() {
      this.form = new Form(this.checkin)
      this.form.$busy = false
    },

    submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const payload = _.extend(this.form.$data(), {
        userId: this.user.id,
        programId: this.selectedProgram.user_program.id,
      })

      this.updateUserCheckin(payload)
        .then((response) => {
          this.form.$timeout(() => {
            this.form.$busy = false
            this.dialog = false

            this.$refs.toast.open({
              color: 'primary',
              message: 'User checkin successfully updated.',
            })

            Event.$emit('reload-user-checkins-list', {
              userProgramId: this.selectedProgram.user_program.id,
            })

            Event.$emit('set-selected-user-checkin', response)
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
        })
    },
  },
}
</script>
