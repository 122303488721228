<template>
  <div v-if="checkinList" class="user-profile--check-in">
    <v-row v-if="checkin && checkinList.length > 0">
      <!-- Weekly check-in stats -->
      <v-col
        v-if="checkinList !== null"
        md="9"
        sm="12"
        class="weekly-check-ins"
      >
        <v-skeleton-loader v-if="loading" type="image" tile />
        <weekly-check-ins v-else :data="checkinDataCollection" />
      </v-col>

      <v-col md="9" sm="12" class="mt-5">
        <v-divider />
      </v-col>

      <!-- Current check-in details -->
      <v-col md="9" sm="12">
        <div class="d-flex justify-space-between align-center flex-wrap">
          <div class="">
            <h2 class="primary--text">Check In</h2>

            <v-skeleton-loader v-if="loading" type="text" width="200" />

            <div v-else class="primary--text">
              <span v-if="checkin.period_from !== null">
                {{ $date(checkin.period_from).format('MMM DD, YYYY') }}
              </span>

              <span v-if="checkin.period_to !== null">
                - {{ $date(checkin.period_to).format('MMM DD, YYYY') }}
              </span>

              <edit-checkin-dialog />
            </div>
          </div>
          <div>
            <v-skeleton-loader v-if="loading" type="button" width="60" />

            <template v-else>
              <user-checkin-switcher
                v-model="currentCheckinId"
                :items="checkinList"
                item-value="id"
                item-text="week"
                label="Select Week"
                return-object
                hide-details
                outlined
                dense
              />
            </template>
          </div>
        </div>

        <v-row>
          <v-col md="6" sm="12">
            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Weight</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.weight }} kg
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Chest</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.chest }} cm
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Waist</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.waist }} cm
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Hips</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.hip }} cm
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Arms</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.arms }} cm
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Legs</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="70" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.legs }} cm
                </p>
              </div>
            </div>
          </v-col>

          <v-col md="6" sm="12">
            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Energy Levels</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="50" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.energy_level }}
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Nutrition Compliance</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="60" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.nutrition_consistency }} %
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Workout Compliance</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="60" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.exercise_consistency }} %
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Positive Changes in Physique</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="50" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.physique_change ? 'Yes' : 'No' }}
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">
                Positive Changes in Strength and Performance
              </p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="50" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.strength_change ? 'Yes' : 'No' }}
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Amount of Food in Meal Guide</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="100" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.meal_plan_amount | readableEnums(hungerTypes) }}
                </p>
              </div>
            </div>

            <v-divider class="mb-3" />

            <div class="d-flex justify-space-between align-center">
              <p class="grey--text">Requested New Meal Guide</p>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="50" />
                <p v-else class="primary--text font-weight-bold">
                  {{ checkin.request_meal_guide ? 'Yes' : 'No' }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="9" sm="12" class="mt-5">
        <v-divider />
      </v-col>

      <!-- Check-in photos -->
      <v-col md="9" sm="12">
        <h2 class="primary--text">Check-In Photos</h2>

        <v-row v-if="loading">
          <v-col :key="number" v-for="number in randomRange" md="3" sm="12">
            <v-skeleton-loader type="image" tile />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col
            v-for="(photo, key) in checkin.body_photos"
            :key="key"
            md="3"
            sm="12"
            class="d-flex child-flex"
          >
            <photo-card v-if="photo" :src="photo.url" />
          </v-col>
        </v-row>

        <v-row v-if="!loading && !hasCheckinPhotos">
          <v-col>
            <div class="no-data d-flex justify-center align-center">
              <h1 class="grey--text headline">NO CHECK-IN PHOTOS</h1>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-else class="no-data d-flex justify-center align-center">
      <h1 class="grey--text headline">NO CHECK-IN DATA</h1>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { get, range, random } from 'lodash'
import Event from '@/services/eventBus'
import UserCheckinSwitcher from '../components/UserCheckinSwitcher'
import WeeklyCheckIns from '../components/WeeklyCheckIns'
import PhotoCard from '../components/PhotoCard'
import EditCheckinDialog from '../components/EditCheckinDialog'

export default {
  name: 'UserCheckIn',

  components: {
    PhotoCard,
    UserCheckinSwitcher,
    WeeklyCheckIns,
    EditCheckinDialog,
  },

  data() {
    return {
      loading: false,
      currentCheckinId: null,
    }
  },

  created() {
    const timer = setInterval(() => {
      if (this.program && this.program.user_program) {
        this.fetchCheckIn(this.program.user_program.id)
        clearInterval(timer)
      }
    }, 300)

    Event.$on('reload-user-checkins-list', ({ userProgramId }) => {
      this.fetchCheckIn(userProgramId, false)
    })

    Event.$on('set-selected-user-checkin', ({ data }) => {
      if (data) {
        this.setCheckin(data)
        this.currentCheckinId = data.id
      }
    })
  },

  destroyed() {
    this.clearCheckinList()
  },

  computed: {
    ...mapState({
      user: (state) => state.users.selectedUser,
      program: (state) => state.users.selectedUserProgram,
      checkinList: (state) => state.users.selectedUserCheckinList,
      checkin: (state) => state.users.selectedUserCheckin,
      hungerTypes: (state) => state.users.extra.types.hunger,
    }),

    randomRange() {
      return range(0, random(1, 4))
    },

    checkinDataCollection() {
      const weeklyCheckIns = this.checkinList

      if (!weeklyCheckIns) {
        return null
      }

      const mappings = {
        labels: [
          '# of Weight',
          '# of Waist',
          '# of Arms',
          '# of Chest',
          '# of Legs',
          '# of Hips',
        ],
        colors: [
          '#F56565',
          '#4299E1',
          '#ECC94B',
          '#38B2AC',
          '#667EEA',
          '#48BB78',
        ],
      }

      return {
        labels: Object.keys(weeklyCheckIns),
        datasets: ['weight', 'waist', 'arm', 'chest', 'legs', 'hip'].map(
          (metric, index) => ({
            label: mappings.labels[index],
            data: Object.values(weeklyCheckIns).map(
              (checkin) => checkin[metric]
            ),
            fill: false,
            backgroundColor: mappings.colors[index],
            borderColor: mappings.colors[index],
          })
        ),
      }
    },

    hasCheckinPhotos() {
      return !!get(this.checkin, 'body_photos', []).length
    },
  },

  watch: {
    program: async function(program, oldProgram) {
      if (oldProgram && program.id !== oldProgram.id) {
        this.fetchCheckIn(program.user_program.id)
      }
    },
  },

  methods: {
    ...mapActions({ getCheckinList: 'users/getUserCheckinList' }),
    ...mapMutations({
      clearCheckinList: 'users/clearUserCheckinList',
      setCheckin: 'users/setUserCheckin',
    }),

    async fetchCheckIn(programId, setDefault = true) {
      this.loading = true

      await this.getCheckinList({ id: this.$attrs.id, programId })

      const defaultCheckin = this.checkinList.slice(-1).pop()
      if (defaultCheckin && setDefault) {
        this.setCheckin(defaultCheckin)
        this.currentCheckinId = defaultCheckin.id
      }

      await this.$sleep()
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.user-profile {
  &--check-in {
    & .weekly-check-ins {
      .v-skeleton-loader {
        &__image {
          height: 400px;
        }
      }
    }

    & .no-data {
      height: 200px;
    }
  }
}
</style>
