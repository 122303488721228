<template>
  <v-select
    v-model="checkin"
    v-bind="$attrs"
    item-value="id"
    :items="items"
    @change="changed"
  >
    <template v-slot:selection="{ item }">
      <span> {{ item.period_from | formatDate('MMM DD, YYYY') }} </span> -
      <span> {{ item.period_to | formatDate('MMM DD, YYYY') }} </span>
      <div style="margin-left: 5px">
        {{ item.type === 1 ? ' (User Checkin)' : ' (User Measurements)' }}
      </div>
    </template>

    <template v-slot:item="{ item }">
      <span> {{ item.period_from | formatDate('MMM DD, YYYY') }} </span> -
      <span> {{ item.period_to | formatDate('MMM DD, YYYY') }} </span>
      <div style="margin-left: 5px">
        {{ item.type === 1 ? ' (User Checkin)' : ' (User Measurements)' }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'UserCheckinSwitcher',

  props: {
    value: [String, Number, Object],
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      checkin: this.value,
    }
  },

  methods: {
    ...mapMutations({
      setUserCheckin: 'users/setUserCheckin',
    }),

    changed(checkIn) {
      this.setUserCheckin(checkIn)

      this.$emit('input', this.checkin)
    },
  },

  watch: {
    value(checkIn) {
      this.checkIn = checkIn
    },
  },
}
</script>
